import React from 'react';
import { htmltoText } from '../util/html-to-text';

interface IPencilBannerHTML {
  bannerHTML: string;
  url: string;
}

export const PencilBannerHTML: React.FC<IPencilBannerHTML> = ({ bannerHTML, url }) => {
  const handleAnalytics = () => {
    if ('utag' in window) {
      // @ts-ignore
      utag.link({
          event: 'pencil banner tracking',
          event_category: 'pencil banner tracking',
          event_action: 'pencil banner click',
          event_label: `${htmltoText(bannerHTML).trim()}`,
          event_noninteraction: 'false'
      });
    }
    window.location.href = url;
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    handleAnalytics();
  };
  return (
    <a href={url} onClick={handleClick} className='mfrm-pencil-banner_html_anchor' tabIndex={-1}>
     <span className='mfrm-pencil-banner_html_container' dangerouslySetInnerHTML={{ __html: bannerHTML }} ></span>
    </a>
  );
};
