import React from 'react';
import dayjs from 'dayjs';
import Countdown, { CountdownRenderProps} from 'react-countdown';
import { isScheduled } from '../util/is-scheduled';

export const PencilBannerCountDown = (props: any) => {
  const countdownData = props.countdownData;
  //check if coundown start/end is undefined;
  if(!countdownData.end && !countdownData.start) { return null; }
  // check if countdown is scheduled to show
  if (!isScheduled(countdownData)) { return null; }

  const format = 'M/D/YYYY H:m:s';
  const splitEndSchedule = countdownData.end ? countdownData.end.split("T") : null;
  const endDate = splitEndSchedule ? splitEndSchedule[0] : null;
  const endTime = splitEndSchedule ? splitEndSchedule[1] : null;
  const scheduleEnd = dayjs(`${endDate} ${endTime}`, format);

  //formatting count down
  const renderer = ({ formatted }: CountdownRenderProps) => {

    const days = formatted.days === '00' ? null : <span className='days'>{formatted.days}:</span>;

    return (
        <span className='mfrm-pencil-banner_countdown_text'>
            {days}<span className='hour'>{formatted.hours}</span>:<span className='minute'>{formatted.minutes}</span>:
                <span className='second'>{formatted.seconds}</span>
        </span>
    );
  };
    return (
      <>
        <Countdown date={scheduleEnd.toDate()} renderer={renderer} />
      </>
    );
};


