import React from 'react';
import { htmltoText } from '../util/html-to-text';

interface IPencilBannerCTA {
  ctaText: string;
  url: string;
  bannerHtml: string;
}

export const PencilBannerCTA: React.FC<IPencilBannerCTA> = ({ctaText, url, bannerHtml }) => {

  const handleAnalytics = () => {
    if ('utag' in window) {
      // @ts-ignore
      utag.link({
          event: 'pencil banner tracking',
          event_category: 'pencil banner tracking',
          event_action: 'pencil banner click',
          event_label: `${htmltoText(bannerHtml).trim()}`,
          event_noninteraction: 'false'
      });
    }
    window.location.href = url;
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    handleAnalytics();
  };

  return (
    <a href={url} onClick={handleClick} className='mfrm-pencil-banner_cta_anchor' tabIndex={-1}>
      <span className='mfrm-pencil-banner_cta_text'>{ctaText}</span>
    </a>
  );
};
