import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CarouselHook } from './types';
import _getViewPort from '../../../Utilities/get-view-port';
import MsDyn365 from '@msdyn365-commerce/core';

const useCarousel: CarouselHook = ({
  slides = [],
  allowTouchMove = false,
  autoplay = {},
  onSlideChange = () => null
}) => {
  const [index, setIndex] = useState<number>(0);
  const [realIndex, setRealIndex] = useState<number>(0);
  const [play, setPlay] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout>();
  const desktopViewport = 768;
  const { lastSlideIndex, slideCount } = useMemo<{
    lastSlideIndex: number;
    slideCount: number;
  }>(
    () => ({
      lastSlideIndex: slides.length - 1,
      slideCount: slides.length
    }),
    [slides.length]
  );


  const enableMouseEvents = useMemo<boolean>(
    () => MsDyn365.isBrowser && window?.innerWidth <= desktopViewport && allowTouchMove,
    [allowTouchMove]
  );

  const nextSlideClickHandler = useCallback(() => {
    setIndex((prevIndex) => prevIndex + 1);
    setRealIndex((prevIndex) =>
      prevIndex === lastSlideIndex ? 0 : prevIndex + 1
    );
  }, [lastSlideIndex]);

  const pausePlay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const stopPlay = () => {
    setPlay(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const startPlay = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(nextSlideClickHandler, autoplay.delay);
    setPlay(true);
  }, [autoplay.delay, nextSlideClickHandler]);

  useEffect(() => {
    const handleVisibilityChange = (event:any) => {
      if (event.target.hidden) {
        pausePlay();
      } else {
        if (play) {
          startPlay();
        }
      }
    };

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange
    );

    return () => {
      document.removeEventListener(
       'visibilitychange',
        handleVisibilityChange
      );
    };
  }, [play, startPlay]);

  useEffect(() => {
    onSlideChange({ play, index: realIndex });
  }, [realIndex, onSlideChange, play]);

  useEffect(() => {
    if (!autoplay.enable || slideCount === 1) {
      return;
    }

    startPlay();

    return () => {
      stopPlay();
    };
  }, [autoplay.enable, startPlay, slideCount]);

  const prevSlideClickHandler = useCallback(() => {
    setIndex((prevIndex) => prevIndex - 1);
    setRealIndex((prevIndex) =>
      prevIndex === 0 ? lastSlideIndex : prevIndex - 1
    );
  }, [lastSlideIndex]);

  const slideChangeHandler = (virtualIndex:number) => {
    let paginationIndex;

    if (virtualIndex >= 0) {
      paginationIndex = virtualIndex % slideCount;
    } else {
      paginationIndex =
        (slideCount - Math.abs(virtualIndex % slideCount)) % slideCount;
    }

    setRealIndex(paginationIndex);
    setIndex(virtualIndex);
  };


  const mouseEnterHandler = () => {
    if (autoplay.pauseOnHover) {
      pausePlay();
    }
  };

  const mouseLeaveHandler = () => {
    if (autoplay.pauseOnHover && play) {
      startPlay();
    }
  };

  return {
    index,
    realIndex,
    slideCount,
    play,
    enableMouseEvents,
    nextSlideClickHandler,
    prevSlideClickHandler,
    slideChangeHandler,
    mouseEnterHandler,
    mouseLeaveHandler
  };
};

export default useCarousel;
