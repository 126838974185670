import React from 'react';
import { IPromoBannerConfig, IPromoBannerProps } from '../../themes/mattressfirm/definition-extensions/promo-banner.ext.props.autogenerated';
import { IMfrmHeaderData } from '../../modules/mfrm-header/mfrm-header.data';
import { bedhubToPencilBannerData, d365ToPencilBannerData } from './pencil-banner-adaptor/pencilBannerAdaptor';
import { isScheduled } from './util/is-scheduled';
import { PencilBannerMessage } from './pencil-banner-components/PencilBannerMessage';
import Carousel from '../components/Carousel';

export const MFRMPencilBanner = (props: IPromoBannerProps<IMfrmHeaderData> & IPromoBannerConfig) => {
  //D365 Data
  const mfrmPencilBannerData = props;
  const d365PencilBannerData = d365ToPencilBannerData(mfrmPencilBannerData);

  //Filter D365 data if scheduled and banner enabled
  d365PencilBannerData!.items = d365PencilBannerData!.items?.filter((item:
    { enableBanner: boolean | undefined, schedule: { end: string | undefined, start: string | undefined } }) => {
    return (item.enableBanner && isScheduled(item.schedule));
  });

  //BedHub Data
  let bedhubDataSource = props?.config?.bedhubDataSource;
  const {
    data: {
      bedhubHeader: { result: bedhubHeader }
    }
  } = props;

  if (bedhubHeader?.data?.ui === undefined) {
    bedhubDataSource = false;
  }

  const bedhubPencilBanner = bedhubHeader?.data?.ui?.pencilBanner;
  const bedhubPencilBannerData = bedhubToPencilBannerData(bedhubPencilBanner);
  const pencilBannerData:any = bedhubDataSource ? (bedhubPencilBannerData || []) : d365PencilBannerData;
  if (!bedhubPencilBannerData?.items?.length) {
    return null;
  }
  //Filter BedHub data if scheduled and banner enabled
  if (bedhubDataSource) {
    bedhubPencilBannerData!.items = bedhubPencilBannerData!.items?.filter((item:
      { enableBanner: boolean | undefined, schedule: { end: string | undefined, start: string | undefined } }) => {
      return (item.enableBanner && isScheduled(item.schedule));
    });
  }

  return (
    <div className='mfrm-pencil-banner_container'>
      <Carousel
        allowTouchMove
        autoplay={{
          enable: true,
          delay: pencilBannerData?.interval || 5000,
          pauseOnHover: true
        }}
        classes={{ carousel: 'carousel' }}
      >
        {
          pencilBannerData?.items.map((slideInfo: any) => (
            <PencilBannerMessage key={slideInfo.ariaLabel} slideInfo={slideInfo}/>
          ))
        }
      </Carousel>
    </div>
  );
};
