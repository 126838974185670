import dayjs from 'dayjs';
//checks if banner is currently scheduled
export const isScheduled = (schedule: {end: string | undefined, start: string | undefined}): Boolean => {
    //check if start/end is undefined.
    if (!schedule.start && !schedule.end) {
      return true;
    }
    const format = 'M/D/YYYY H:m:s';

    const splitStartSchedule = schedule.start ? schedule.start.split("T") : null;
    const startDate = splitStartSchedule ? splitStartSchedule[0] : null;
    const startTime = splitStartSchedule ? splitStartSchedule[1] : null;
    const start = dayjs(`${startDate} ${startTime}`, format);

    const splitEndSchedule = schedule.end ? schedule.end.split("T") : null;
    const endDate = splitEndSchedule ? splitEndSchedule[0] : null;
    const endTime = splitEndSchedule ? splitEndSchedule[1] : null;
    const end = dayjs(`${endDate} ${endTime}`, format);

    // check if schedule is valid
    const validStart = start && start.isValid();
    const validEnd = end && end.isValid();

    if (!validStart || !validEnd) {
        return false;
    }

    const now = dayjs();

    return now.isBetween(start, end);
  };