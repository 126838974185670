import { cloneElement, FC, Fragment, ReactElement, ReactNode } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { CarouselConfig } from './types';
import { mod } from './utils';
import React from 'react';
import useCarousel from './useCarousel';

type PropTypes = { children: ReactNode[] } & CarouselConfig;

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const Carousel: FC<PropTypes> = (props) => {
  const {
    index,
    slideCount,
    enableMouseEvents,
    slideChangeHandler,
    mouseLeaveHandler,
    mouseEnterHandler
  } = useCarousel({ slides: props.children, ...props });
  const {
    children,
    classes = {}
  } = props;

  const slideRenderer = ({ index }:any) => {
    const child = children[mod(index, slideCount)] as ReactElement;
    if (!child) {
      return null;
    }
    return <Fragment key={index}>{cloneElement<unknown>(child)}</Fragment>;
  };


  return (
    <section
      className={classes.carousel}
      aria-roledescription="carousel"
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <VirtualizeSwipeableViews
        animateTransitions
        enableMouseEvents={enableMouseEvents}
        index={index}
        onChangeIndex={slideChangeHandler}
        slideRenderer={slideRenderer}
        overscanSlideAfter={1}
        overscanSlideBefore={1}
        springConfig={{ duration: '0.7s', delay: '0s', easeFunction: 'ease' }}
      />
    </section>
  );
};

export default Carousel;
