import React, { useRef } from 'react';
import { PencilBannerCountDown } from './PencilBannerCountDown';
import { PencilBannerHTML } from './PencilBannerHTML';
import { PencilBannerCTA } from './PencilBannerCTA';
import { CartDataTestIds } from '../../../common/automation/data-testids';

export const PencilBannerMessage = ( props:any ) => {
  const {slideInfo} = props;
  const messageRef = useRef<HTMLDivElement>(null);
  const ariaHiddenNode = messageRef?.current?.parentNode as HTMLDivElement;
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const currentNode = anchorRef?.current as HTMLAnchorElement;
  const isMessageHidden = ariaHiddenNode?.getAttribute('aria-hidden') === 'true';
  currentNode?.setAttribute("tabIndex", isMessageHidden ? "-1" : "0");
  const handleKeyDown = (e: {key: string}, url?:string) => {
    if (e.key === 'Enter') {
      window.location.href= url ? url : '';
    }
  };

  return (
         <div className='mfrm-pencil-banner-item' ref={messageRef}  data-testid={CartDataTestIds.PencilBanner}>
            <a
              href={slideInfo?.url}
              className='mfrm-pencil-banner-item-link'
              onKeyDown={()=>handleKeyDown(slideInfo?.url)}
              tabIndex={-1}
              ref={anchorRef}
            >
             <PencilBannerHTML bannerHTML={slideInfo?.html} url={slideInfo?.url} />
             <PencilBannerCountDown countdownData={slideInfo?.countdown} />
             <PencilBannerCTA bannerHtml={slideInfo?.html} url={slideInfo?.url} ctaText={slideInfo?.label} />
            </a>
         </div>
  );
};
