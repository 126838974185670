export const htmltoText = (html: string) => {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    // tslint:disable-next-line: no-inner-html
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  };