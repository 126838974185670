/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { IMfrmHeaderData } from "../../../modules/mfrm-header/mfrm-header.data";
import { IPromoBannerConfig, IPromoBannerProps } from "../../../themes/mattressfirm/definition-extensions/promo-banner.ext.props.autogenerated";

export interface UIPencilBannerData {
    interval: Number | undefined
    items?: UIPencilBannerItem [] | null
    next?: UICarouselButton | null
    previous?: UICarouselButton | null
}

export interface UIPencilBannerItem extends UILink {
    enableBanner: Boolean
    html: String
    schedule?: UIDateRange
    countdown?: UIDateRange
    }

export interface UIDateRange {
    start: String | undefined
    end: String | undefined
}

export interface UILink {
    ariaLabel?: String | null
    label: String | undefined
    type?: UILinkType [] | null
    url: String | undefined
}

enum UILinkType {
    CHAT
}

export interface UICarouselButton {
        ariaLabel?: String
        image?: {
            url: String
        }
        label?: String
}

export const d365ToPencilBannerData = (mfrmPencilBanner: IPromoBannerProps<IMfrmHeaderData> & IPromoBannerConfig | undefined ) => {
    if (mfrmPencilBanner) {
        return {
            interval: mfrmPencilBanner.config.interval,
            items :
                mfrmPencilBanner?.config.mfrmBannerMessages?.map(item => {
                    return {
                        enableBanner: item.enableBanner,
                        ariaLabel: item?.name,
                        countdown : {
                            end: (item.countdownSchedule?.enableCountdown ? (`${item?.countdownSchedule?.endDate!}T${item?.countdownSchedule?.endTime!}`) : undefined),
                            start: (item.countdownSchedule?.enableCountdown ? (`${item?.countdownSchedule?.startDate!}T${item?.countdownSchedule?.startTime!}`) : undefined)
                        },
                        html: item.richText,
                        label: item.links?.map((label: any) => { return label.linkText; })[0],
                        schedule: {
                            end: (item.bannerSchedule?.enableBannerSchedule ? (`${item?.bannerSchedule?.endDate!}T${item?.bannerSchedule?.endTime!}`) : undefined),
                            start: (item.bannerSchedule?.enableBannerSchedule ? (`${item?.bannerSchedule?.startDate!}T${item?.bannerSchedule?.startTime!}`) : undefined)
                        },
                        url: item.links?.map((url: any) => { return url.linkUrl.destinationUrl; })[0]
                    };
               }),
            next: {
                ariaLabel: mfrmPencilBanner?.config?.next?.altText,
                image: {
                    url: mfrmPencilBanner?.config?.next?.src
                },
                label: mfrmPencilBanner?.config?.next?.label
            },
            previous: {
                ariaLabel: mfrmPencilBanner?.config?.previous?.altText,
                image: {
                    url: mfrmPencilBanner?.config?.previous?.src
                },
                label:mfrmPencilBanner?.config?.previous?.label
            }

        };
    } return null;
};


//BedHub Data
export const bedhubToPencilBannerData = (bedhubData: IMfrmHeaderData | undefined) => {
    if (bedhubData !== undefined || null) {
        return {
            interval: bedhubData?.interval,
            items :
                bedhubData?.items?.map((item: any) => {
                    return {
                        enableBanner: true,
                        ariaLabel: item.ariaLabel,
                        countdown : {
                            end: item?.countdown?.end,
                            start: item?.countdown?.start
                        },
                        html: item.html,
                        label: item.label,
                        schedule: {
                            end: item?.schedule?.end,
                            start: item?.schedule?.start
                        },
                        type: item.type,
                        url: item.url
                    };
            }),
            next: {
                ariaLabel: bedhubData?.next?.ariaLabel,
                image: {
                    url: bedhubData?.next?.image.url
                },
                label: bedhubData?.next?.label
            },
            previous: {
                ariaLabel: bedhubData?.previous?.ariaLabel,
                image: {
                    url: bedhubData?.previous?.image.url
                },
                label: bedhubData?.previous?.label
            }
        };
    }
        return null;
};